    //Image input preview container
    const imageElements = document.querySelectorAll('[type="file"][accept="image/*"]');
    const imagePreviewElements = document.querySelectorAll('img[data-img-preview]');

    if(imagePreviewElements && imagePreviewElements.length > 0){
        imagePreviewElements.forEach(element => {
            let closest = element.closest('.d-none');
            let src = element.getAttribute('src');

            if(src){
                if(closest && closest.matches('.d-none')){
                    closest.classList.remove('d-none');
                }
            }else{
                closest.classList.add('d-none');
            }
        });
    }

    if(imageElements && imageElements.length > 0){
        imageElements.forEach(element => {
            element.addEventListener('change', function(e, i){
                let previewContainer = this.getAttribute('data-img-set-preview');
                let previewImgContainer = document.querySelector(`img[data-img-preview="${previewContainer}"]`);

                if (this.files && this.files[0]) {
                    var reader = new FileReader();
                    
                    reader.onload = function(e) {
                        previewImgContainer.setAttribute('src', e.target.result);
                        imagePreviewElements.forEach(element => {
                            let closest = element.closest('.d-none');
                            let src = element.getAttribute('src');

                            if(src){
                                if(closest && closest.matches('.d-none')){
                                    closest.classList.remove('d-none');
                                }
                            }else{
                                closest.classList.add('d-none');
                            }
                        });
                    }
                    
                    reader.readAsDataURL(this.files[0]);
                }
            });
        });
    }

        //Sidebar Menu Active for current url
        const menus = document.querySelectorAll('#dropdown-active-list .dropdown-list-item');
        if(menus && menus.length > 0){
            const url = window.location.href;    
            menus.forEach(element => {
                let href = element.querySelector('a').getAttribute('href');
                if(href === url){
                    element.classList.add('active');
                }else{
                    element.classList.remove('active');
                }
            });
        }