const app = require('../utils/app.core');

/**
 *  LATEST REVIEWS RATING
 */
app.createArc({
  container: '#footer-rate-1',
  width: 48,
  height: 48,
  lineWidth: 5,
  gradient: {
    colors: ['#5216fd', '#ff055d']
  },
  scale: {
    start: 0,
    end: 10,
    stop: 8.7
  },
  shadow: true
});

app.createArc({
  container: '#footer-rate-2',
  width: 48,
  height: 48,
  lineWidth: 5,
  gradient: {
    colors: ['#5216fd', '#ff055d']
  },
  scale: {
    start: 0,
    end: 10,
    stop: 6.5
  },
  shadow: true
});

app.createArc({
  container: '#footer-rate-3',
  width: 48,
  height: 48,
  lineWidth: 5,
  gradient: {
    colors: ['#5216fd', '#ff055d']
  },
  scale: {
    start: 0,
    end: 10,
    stop: 9.2
  },
  shadow: true
});

app.createArc({
  container: '#footer-rate-4',
  width: 48,
  height: 48,
  lineWidth: 5,
  gradient: {
    colors: ['#5216fd', '#ff055d']
  },
  scale: {
    start: 0,
    end: 10,
    stop: 6.5
  },
  shadow: true
});